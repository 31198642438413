// import { ReactComponent as TourNowText } from 'src/assets/images/tour-now-icon.svg';
import { ReactComponent as NterNowIcon } from 'src/assets/images/icn-logo.svg';
// import { ReactComponent as TourNowPoster } from 'src/assets/images/tour-now-poster.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'src/state/store';
import TourNowText from 'src/components/UI/TourNowText';
import NterNowPoster from 'src/components/UI/TourNowPoster';
import { Link } from 'react-scroll'
import { useLocation } from 'react-router-dom';

const Poster = () => {

  const builderDetails = useSelector((state: RootState) => state.builder.settings);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const removeHeaderPic = queryParams.get('removeHeaderPic');

  return (
    <div className='mt-5 tour-now-poster container'>
      <div className='poster-content'>
        <TourNowText />
        {!removeHeaderPic && 
          <>
            <div className='content-box'>
              <h1>Book and visit your dream home anytime</h1>
              <p>Experience the freedom and convenience of touring your future dream home at your own pace.</p>
            </div>
            <div className='nternow-icon-box'>
              Powered By <NterNowIcon className='nternow-icon' />
            </div>
            <div className='button-container'>
              <Link 
              activeClass="active" 
              to="explore-properties" 
              spy={true} 
              smooth={true} 
              duration={50}
              className='scroll-link'>
                <button 
                  className='tour-now-button explore-btn'
                  style={{
                      backgroundColor: builderDetails?.primary_color ? builderDetails?.primary_color : '#3e864a',
                      color: 'white',
                      border: 'none',
                      boxShadow: '0px 10px 16px #fafafa'
                  }}
                >
                  EXPLORE ALL <b>NterNow</b> PROPERTIES
                </button>
              </Link>

              <Link 
              activeClass="active" 
              to="how-it-works" 
              spy={true} 
              smooth={true} 
              duration={50}
              className='scroll-link'>
                <button className='tour-now-button'>See how it works</button>
              </Link>
            </div>
          </>
        }
      </div>

      <div className='poster-img' style={removeHeaderPic ? { height: '75%'} : {}}>
        {removeHeaderPic ? 
          <div className='poster-content'>
            <div className='content-box'>
              <h1>Book and visit your dream home anytime</h1>
              <p>Experience the freedom and convenience of touring your future dream home at your own pace.</p>
            </div>
            <div className='nternow-icon-box'>
              Powered By <NterNowIcon className='nternow-icon' />
            </div>
            <div className='button-container'>
              <Link 
              activeClass="active" 
              to="explore-properties" 
              spy={true} 
              smooth={true} 
              duration={50}
              className='scroll-link'>
                <button 
                  className='tour-now-button explore-btn'
                  style={{
                      backgroundColor: builderDetails?.primary_color ? builderDetails?.primary_color : '#3e864a',
                      color: 'white',
                      border: 'none',
                      boxShadow: '0px 10px 16px #fafafa'
                  }}
                >
                  EXPLORE ALL <b>NterNow</b> PROPERTIES
                </button>
              </Link>

              <Link 
              activeClass="active" 
              to="how-it-works" 
              spy={true} 
              smooth={true} 
              duration={50}
              className='scroll-link'>
                <button className='tour-now-button'>See how it works</button>
              </Link>
            </div>
          </div> : <NterNowPoster />  
        } 
      </div>
    </div>
  )
}

export default Poster;